import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CashBalance, Company} from '../CashBalance.types'
import {SelectDropdown} from '../../../Molecules/SelectDropdown/SelectDropdown'

const useStyles = makeStyles(() => ({
  button: {
    width: 'auto',
    fontSize: 14
  },
  long: {
    width: 'auto'
  }
}))

interface CompanySelect {
  company?: Company
  onLight?: boolean
}

const SelectItem: React.FC<CompanySelect> = ({company}) => (
  <>
    <Typography component="div" variant="body2" color="textPrimary">
      {company?.companyName || ''}
    </Typography>
  </>
)

interface SubTitleWithDropdownDropdownType {
  label?: string
  dark?: boolean
  long?: boolean
  onLight?: boolean
  selectedItem?: CashBalance | null
  chosenValueType?: 'customer' | 'payer' | null
  loading?: boolean
  showTitleOnlyEnabled?: boolean
  customersOnly?: boolean
  onChange?: any
  options?: Array<CashBalance>
  smallScreen?: boolean
}
export const SubTitleWithDropdown: React.FC<SubTitleWithDropdownDropdownType> = ({
  selectedItem,
  dark = true,
  onLight,
  loading,
  onChange,
  options,
  smallScreen = false,
  ...props
}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  if (!options || options.length === 0) {
    return null
  }
  return (
    <Box
      display="flex"
      flexDirection={smallScreen ? 'column' : 'row'}
      alignItems={smallScreen ? 'flex-start' : 'center'}
      justifyContent="space-between"
    >
      <Typography
        variant="body2"
        customColor="textPrimarySoft"
        component="div"
        style={{textTransform: 'uppercase', fontWeight: 700, fontSize: 12}}
        data-test-id="company-dropdown"
      >
        {smallScreen ? t('finance.summaryForCompany') : t('overview.cashBalance.summaryForCompany')}
      </Typography>
      <SelectDropdown
        selectedProps={{
          className: classNames(classes.button)
        }}
        data-test-id="subtitle-with-dropdown"
        dark={dark}
        long
        inWidget
        showSearch={false}
        label={''}
        loading={loading}
        options={options}
        renderItem={(item: CashBalance) => <SelectItem company={item.company} onLight={onLight} />}
        stringifyItem={(item: CashBalance) => `${item.company?.companyName}`}
        renderChosenItem={(item: CashBalance) => `${item.company?.companyName}`}
        keyExtractor={(item: CashBalance) => item.company?.companyCode || ''}
        onChange={(item: CashBalance) => onChange(item)}
        selectedItem={selectedItem ? selectedItem : options[0]}
        onLight={onLight}
        {...props}
      />
    </Box>
  )
}
