/* eslint-disable complexity */
import {ContentSnippet, getContentSnippetByPlantId} from '@hconnect/apiclient'
import {User} from '@hconnect/apiclient/src'
import {useRolesBusinessLogic} from '@hconnect/common/Invite/hooks/useRoles'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Divider, Page, Typography, withRoute, dateFormatter} from '@hconnect/uikit'
import {Box, Button, Grid, TextField, makeStyles} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {useDebounce} from '@react-hook/debounce'
import find from 'lodash/find'
import first from 'lodash/first'
import get from 'lodash/get'
import moment from 'moment'
import queryString from 'query-string'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {api} from '../../../../App.global'
import {useContainerStyles} from '../../../../common/styles/containerStyles'
import {TitleNavigation} from '../../../../Molecules/TitleNavigation'
import {CustomerStateType, selectSelectedCustomerFilter} from '../../../../Organisms/Customers'
import {selectCustomers} from '../../../../Organisms/Customers/Customers.selector'
import {Customer} from '../../../../Organisms/Customers/Customers.types'
import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {CustomerSimpleLookup} from '../../../../Organisms/Customers/CustomerSimpleLookup'
import {useFeaturesState} from '../../../../Organisms/Features'
import {Site, SitesFilter} from '../../../../Organisms/SitesTypeahead'
import {usePermissions} from '../../../../Permissions'
import {AppState} from '../../../../Root.store'
import {Materials, QCData, useMaterialCertificatesForQC} from '../hooks'
import {MaterialCertificate} from '../types'

import logoUrl from './Pdf/HM.png'
import {PdfData, createPdfDocument, downloadBlob} from './Pdf/PdfUtils'
import {PdfViewer} from './Pdf/PdfViewer'
import {BusinessLineType} from '../../../../common/types'
import {PageNames} from '../../../../common/constants'
import {SelectDropdown} from '../../../../Molecules/SelectDropdown/SelectDropdown'
import Content from '../../../../Molecules/Content'

export const GenerateCertificateRoute = '/generateCertificate'

const TYPE_TITLETEXT = 'plantQCTitleText'
const TYPE_NAMETEXT = 'plantQCNameText'
const LIMIT_MATERIAL_CERTIFICATES = 1500

const useStyles = makeStyles(() => ({
  popper: {
    boxShadow: '0px 0px 11px -3px #000000',
    borderRadius: '4px'
  },
  fullWidth: {
    width: '100%'
  }
}))

const GenerateCertificate: React.FC = () => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const {displayCustomerSimpleLookup} = usePermissions()
  const {getFeature} = useFeaturesState()

  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const customers = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const customer = useSelector<AppState, Customer | null>((state) =>
    selectSelectedCustomerFilter(state)
  )

  const {plantId: plantIdFromQS, materialNumber: materialNumberFromQS} = queryString.parse(
    window.location.search
  )
  const {
    classes: {pageComponentContainer, pageContainer}
  } = useContainerStyles()

  const [selectedCementType, setSelectedCementType] = useState<Materials[]>([])

  const [selectedPlantId, setSelectedPlantId] = useState((plantIdFromQS as string) || '')

  const [snippets, setSnippets] = useState<ContentSnippet[]>([])

  const [site, setSite] = useState<Site | undefined>(undefined)

  const [address, setAddress] = useState<string>('')
  const [streetAddress, setStreetAddress] = useState<string>('')
  const [destinationName, setDestinationName] = useState<string>('')

  const selectedCust = useSelector<AppState, Customer | null>((state) =>
    get(selectCustomers(state), 'selectedCustomer')
  )

  const selectedCustomer = selectedCust
    ? customers.customers.find((customer) => customer.customerId === selectedCust.customerId)
    : null

  const [job, setJob] = useState('')
  const [contractor, setContractor] = useState('')
  const [masonry, setMasonry] = useState('')
  const [architect, setArchitect] = useState('')
  const [selectedPlantItem, setSelectedPlantItem] = useState<QCData>()
  const [isPlantPreselected, setIsPlantPreselected] = useState<boolean>(false)

  const [pdfContentAsString, setPdfContentAsString] = useDebounce('', 1000)
  const customerSearchByNameEnabled = getFeature('CustomersByName')

  const titleText = snippets.find((snippet) => snippet.type === TYPE_TITLETEXT)?.body || ''
  const signatureText = snippets.find((snippet) => snippet.type === TYPE_NAMETEXT)?.body || ''

  const materialSnippetsFromSelectedCement = useMemo(
    () =>
      snippets
        .filter(
          (snippet) =>
            snippet.materialNumber &&
            selectedCementType.some((cement) => cement.materialNumber === snippet.materialNumber)
        )
        .map((snippet) => snippet.body),
    [selectedCementType, snippets]
  )

  const showPdf =
    !!pdfContentAsString && (Boolean(materialNumberFromQS) || selectedCementType.length !== 0)

  const {isQCSupervisor, isOnlyQCSupervisor} = useRolesBusinessLogic()
  const {data: locData, isFetching} = useMaterialCertificatesForQC({
    limit: LIMIT_MATERIAL_CERTIFICATES,
    enabled: isQCSupervisor,
    customer
  })
  const {materialCertificates, qcDataItems} = locData || {}

  const [certsForMultiselect, setCertsForMultiSelect] = useState<Materials[]>()

  useEffect(() => {
    if (selectedPlantId && qcDataItems) {
      const materials =
        qcDataItems.find((plant) => plant.plantId === selectedPlantId)?.materialsProduced || []
      setCertsForMultiSelect(materials)
    }
  }, [selectedPlantId])

  useEffect(() => {
    setStreetAddress(site ? `${site.street}, ${site.city}, ${site.state} ${site.postalCode}` : '')
    setDestinationName(site ? site.siteName : '')
  }, [site])

  useEffect(() => {
    setAddress(`${destinationName}\n${streetAddress}`)
  }, [streetAddress, destinationName])

  useEffect(() => {
    const pdfData: PdfData = {
      date: dateFormatter(moment(), language),
      address,
      titleText,
      signatureText,
      materialText: materialSnippetsFromSelectedCement,
      job,
      contractor,
      masonry,
      architect,
      logoUrl
    }
    // eslint-disable-next-line no-void
    void createPdfDocument(setPdfContentAsString, pdfData, t)
  }, [
    selectedCustomer,
    titleText,
    signatureText,
    materialSnippetsFromSelectedCement,
    address,
    site,
    job,
    contractor,
    masonry,
    architect,
    t,
    setPdfContentAsString
  ])

  useEffect(() => {
    const firstQCDataItem = first(qcDataItems)
    if (!selectedPlantId && firstQCDataItem) {
      setSelectedPlantId(firstQCDataItem.plantId)
      setSelectedPlantItem(find(qcDataItems, {plantId: firstQCDataItem.plantId}))
      return
    }
    const getContentSchnippets = async () => {
      const result = await getContentSnippetByPlantId(api)(selectedPlantId)
      setSnippets(result.data)
    }
    // eslint-disable-next-line no-void
    void getContentSchnippets()
  }, [selectedPlantId, qcDataItems])

  useEffect(() => {
    setIsPlantPreselected(plantIdFromQS !== undefined && materialNumberFromQS !== undefined)
  }, [plantIdFromQS, materialNumberFromQS])

  const handleChangePlant = (plantId: string) => {
    setSelectedCementType([])
    setSelectedPlantItem(find(qcDataItems, {plantId: plantId}))

    setSelectedPlantId(plantId)
  }

  const preselectedCertificate = materialCertificates?.find(
    (cert) => cert.materialNumber === materialNumberFromQS && cert.plantId === plantIdFromQS
  )

  useEffect(() => {
    if (preselectedCertificate) {
      setSelectedCementType([preselectedCertificate])
      setSelectedPlantItem(find(qcDataItems, {plantId: `${plantIdFromQS}`}))
    }
  }, [preselectedCertificate, plantIdFromQS, qcDataItems])

  useEffect(() => {
    setSite(undefined)
  }, [selectedCustomer])

  return (
    <Content style={{padding: '4px 8px 8px 8px'}}>
      <div className={pageContainer}>
        <TitleNavigation
          title={t('certificate.titleDefault')}
          onClick={() => history.goBack()}
          ariaLabel="Generate Certificate Title"
        />

        <Page
          boxed
          variant="sheet"
          className={pageComponentContainer}
          title={
            <Box marginBottom={2} aria-label="Header invoice number">
              {t('certificate.generateCertificate.headerTitle')}
              <Typography variant="caption" customColor="textSecondarySoft" component="div">
                {t('certificate.generateCertificate.subTitle')}
              </Typography>
            </Box>
          }
        >
          <Grid container spacing={6}>
            <Grid item xs={6}>
              {!isOnlyQCSupervisor ? (
                <Box marginBottom={2}>
                  <Typography variant="h3">
                    {t('certificate.generateCertificate.companyNameAndAddressTitle')}
                  </Typography>
                  <Divider color="primary" />
                  <Typography customColor="textPrimarySoft" variant="caption">
                    {t('certificate.generateCertificate.companyNameAndAddressSubTitle')}
                  </Typography>
                </Box>
              ) : null}

              {!isOnlyQCSupervisor ? (
                <Box pb={4}>
                  {displayCustomerSimpleLookup ? (
                    <CustomerSimpleLookup
                      data-test-id="loc-customer-lookup"
                      customerSearchByName={customerSearchByNameEnabled}
                      page={PageNames.GENERATE_CERTIFICATES}
                    />
                  ) : (
                    <CustomersFilter
                      data-test-id="loc-customer-dropdown"
                      onLight
                      page={PageNames.GENERATE_CERTIFICATES}
                    />
                  )}
                </Box>
              ) : null}
              {selectedCustomer?.customerId ? (
                <Box marginBottom={2} data-test-id="loc-destination">
                  <Box marginBottom={2}>
                    <Typography variant="h3">
                      {t('certificate.generateCertificate.selectDestinationTitle')}
                    </Typography>
                    <Divider color="primary" />
                    <Typography customColor="textPrimarySoft" variant="caption">
                      {t('certificate.generateCertificate.selectDestinationSubTitle')}
                    </Typography>
                  </Box>
                  <SitesFilter
                    data-test-id="loc-site-dropdown"
                    customerId={selectedCustomer?.customerId}
                    limit={100}
                    setSite={setSite}
                    site={site}
                    page={PageNames.GENERATE_CERTIFICATES}
                  />
                </Box>
              ) : null}
              <Box marginBottom={2} data-test-id="loc-cement">
                <Box marginBottom={2}>
                  <Typography variant="h3">
                    {t('certificate.generateCertificate.selectCertificationTitle')}
                  </Typography>
                  <Divider color="primary" />
                  <Typography customColor="textPrimarySoft" variant="caption">
                    {t('certificate.generateCertificate.selectCertificationSubTitle')}
                  </Typography>
                </Box>
                <SelectDropdown
                  label={t('certificate.plantsDropdown.label')}
                  className={classes.fullWidth}
                  options={qcDataItems}
                  loading={isFetching}
                  disabled={isPlantPreselected}
                  renderItem={(item: MaterialCertificate) => <Box>{item.plantName}</Box>}
                  stringifyItem={(item: MaterialCertificate) => `${item.plantName}`}
                  keyExtractor={(item: MaterialCertificate) => `${item.plantId}`}
                  onChange={(item: MaterialCertificate) => handleChangePlant(item.plantId)}
                  selectedItem={selectedPlantItem}
                />
                <Typography customColor="textPrimarySoft" variant="caption">
                  {t('certificate.generateCertificate.changePlantNotification')}
                </Typography>

                <Autocomplete
                  multiple
                  disabled={isPlantPreselected || !selectedPlantId}
                  options={certsForMultiselect ?? []}
                  classes={{popper: classes.popper}}
                  value={preselectedCertificate ? [preselectedCertificate] : selectedCementType}
                  onChange={(evt, value) => setSelectedCementType(value)}
                  getOptionLabel={(option) => option.materialDescription}
                  style={{width: 600}}
                  renderInput={(params) => (
                    <TextField {...params} label="Cement type" variant="standard" />
                  )}
                />
              </Box>
              <Box p={4} />
              <Box>
                <Typography variant="h3">
                  {t('certificate.generateCertificate.optionalInformation')}
                </Typography>
                <Divider color="primary" />
                <Typography customColor="textPrimarySoft" variant="caption">
                  {t('certificate.generateCertificate.notRequiredToLoc')}
                </Typography>
              </Box>
              <Box p={2.5} />
              <Box marginBottom={2} display="flex" flexDirection="column" alignItems="flex-start">
                <TextField
                  label="Destination"
                  variant="filled"
                  value={destinationName}
                  fullWidth
                  onChange={(e) => setDestinationName(e.target.value)}
                />
                <Box p={1} />
                <TextField
                  label="Address"
                  variant="filled"
                  value={streetAddress}
                  fullWidth
                  onChange={(e) => setStreetAddress(e.target.value)}
                />
                <Box p={1} />
                <TextField
                  label="Job"
                  variant="filled"
                  value={job}
                  fullWidth
                  onChange={(e) => setJob(e.target.value)}
                />
                <Box p={1} />
                <TextField
                  label="General Contractor"
                  variant="filled"
                  value={contractor}
                  fullWidth
                  onChange={(e) => setContractor(e.target.value)}
                />
                <Box p={1} />
                <TextField
                  label="Masonry"
                  variant="filled"
                  value={masonry}
                  fullWidth
                  onChange={(e) => setMasonry(e.target.value)}
                />
                <Box p={1} />
                <TextField
                  label="Architect"
                  variant="filled"
                  value={architect}
                  fullWidth
                  onChange={(e) => setArchitect(e.target.value)}
                />
                <Box p={1} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  data-test-id="generate-certificate-button"
                  color="primary"
                  onClick={() => {
                    downloadBlob(pdfContentAsString)
                    trackEvent('hubDownloadSingle', {
                      product: 'hub',
                      downloadedItem: 'LOC',
                      customerId: customer?.customerId,
                      customerName: customer?.customerName,
                      entryPoint: 'Certificate -> DownloadPDF',
                      downloadedItemPosition: 0,
                      plant: selectedPlantId,
                      material: 'Cement',
                      userId: user?.user_id,
                      userMail: user?.eMail,
                      userCountry: user?.country,
                      userRoles: user?.hasRoles,
                      userIsInternal: user?.isInternal,
                      downloadedItemBusinessLine: BusinessLineType.CEM
                    })
                  }}
                  disabled={!showPdf}
                >
                  {t('certificate.generateCertificate.downloadPDF')}
                </Button>
              </Box>
              <Box p={1} />
              <PdfViewer file={pdfContentAsString} showPdf={showPdf} />
            </Grid>
          </Grid>
        </Page>
      </div>
    </Content>
  )
}

export default withRoute({
  path: GenerateCertificateRoute,
  hideInMainNav: true,
  routeProps: {exact: true},
  label: 'Generate Certificate'
})(GenerateCertificate)
