import {Typography} from '@hconnect/uikit'
import {Theme} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Payer} from './Payers.types'
import {SelectDropdown} from '../../Molecules/SelectDropdown/SelectDropdown'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: theme.spacing(27.75)
  }
}))

interface SelectItemType {
  payer: Payer
  onLight?: boolean
}

const SelectItem: React.FC<SelectItemType> = ({payer, onLight}) => (
  <>
    <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
      {payer.payerName}
    </Typography>
    <Typography variant="caption" customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}>
      {payer.payerNumber || ''}
    </Typography>
  </>
)

interface PayersDropdownType {
  selectedPayerId: string
  props?: any
  label?: string
  noSelectionLabel?: any
  onChange: (value: Payer) => void
  payers?: Payer[]
  isFetching?: boolean
  onLight?: boolean
}

export const PayersDropdown: React.FC<PayersDropdownType> = ({
  selectedPayerId,
  noSelectionLabel,
  payers,
  isFetching = false,
  onLight = false,
  onChange
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const selectedItem = payers
    ? payers.find((payer) => payer.payerId === selectedPayerId)
    : undefined

  if (!payers || payers.length === 0) {
    return null
  }

  return (
    <SelectDropdown
      data-test-id="payer-dropdown"
      selectedProps={{
        className: classes.button
      }}
      loading={isFetching}
      onLight={onLight}
      label={t('customers.customerDropdown.label')}
      options={payers}
      renderItem={(item: Payer) => <SelectItem payer={item} onLight={onLight} />}
      stringifyItem={(item: Payer) => `${item.payerName} ${item.payerNumber || ''}`}
      keyExtractor={(item: Payer) => item.payerId}
      selectedItem={selectedItem || (payers && payers[0])}
      noSelectionLabel={noSelectionLabel}
      onChange={onChange}
    />
  )
}
