import {trackEvent} from '@hconnect/common/logging/Analytics'
import {ErrorPaper, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import {debounce, get, some} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {AppState} from '../../Root.store'
import {selectCustomers} from '../Customers'
import {Customer} from '../Customers/Customers.types'
import {removeGlobalSiteFilter} from '../Projects'

import {fetchSites} from './Sites.action'
import {selectSites} from './Sites.selector'
import {Site} from './Sites.types'
import {SelectDropdown} from '../../Molecules/SelectDropdown/SelectDropdown'

const toAddress = (site: Site, linebreak = '\n') =>
  `${site.street ? site.street + linebreak : ''} ${site.city}, ${site.state}, ${site.postalCode}`

const useStyles = makeStyles(() => ({
  button: {
    width: 222
  }
}))

interface SelectItemType {
  site: Site
  t: any
  onLight?: boolean
}
const SelectItem: React.FC<SelectItemType> = ({site, t, onLight}) => {
  const {siteNumber} = site
  const id = siteNumber && siteNumber !== '0' ? siteNumber : undefined
  return (
    <Box aria-label={`Site filter option ${site.siteName}`}>
      <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
        {site.siteName}
      </Typography>
      <Typography
        variant="caption"
        color={onLight ? 'textPrimary' : 'textSecondary'}
        component="div"
        dangerouslySetInnerHTML={{
          __html: toAddress(site, '<br />')
        }}
      />
      {id && (
        <Typography
          component="div"
          variant="caption"
          customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}
        >
          {t('order.orderDropdown.id')} {id}
        </Typography>
      )}
    </Box>
  )
}

interface SitesDropdownType {
  sites: Site[]
  isFetching: boolean
  error: any
  fetchSites: (customerId: string) => void
  selectedSiteId: string
  selectedCustomer: Customer | null
  removeFilter: () => void
  customers: Customer[]
  props?: any
  label?: string
  noSelectionLabel: any
  onChange?: (value: Site) => void
  onLight?: boolean
}
const SitesDropdown: React.FC<SitesDropdownType> = ({
  sites,
  isFetching,
  error,
  fetchSites,
  selectedSiteId,
  selectedCustomer,
  removeFilter,
  onLight,
  ...props
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [options, setOptions] = useState(sites)
  useEffect(() => {
    if (!isFetching && selectedCustomer) {
      fetchSites(selectedCustomer.customerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer])
  useEffect(() => {
    if (selectedCustomer) {
      const filteredSites = sites
        ? sites.filter((site: Site) => site.customerId === selectedCustomer.customerId)
        : []
      if (selectedSiteId && !some(filteredSites, {siteId: selectedSiteId})) {
        removeFilter()
      }
      setOptions(filteredSites)
    } else {
      setOptions(sites)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, sites])

  const selectedItem = sites ? sites.find((site) => site.siteId === selectedSiteId) : undefined
  const {noSelectionLabel, ...restProps} = props

  const debounceTypeaheadSearchTerm = useCallback(
    debounce(
      (term) =>
        trackEvent('typeaheadSearchTerm', {
          product: 'hub',
          type: 'destination',
          searchTerm: term
        }),
      200
    ),
    []
  )

  return (
    <SelectDropdown
      data-test-id="sites-dropdown"
      selectedProps={{
        className: classNames(classes.button)
      }}
      label="Sites"
      noSelectionLabel={noSelectionLabel || t('order.orderDropdown.noSelectionLabel')}
      showError={!!error}
      errorRender={() => (
        <ErrorPaper
          variant="primaryDark"
          onRetry={() => fetchSites(selectedCustomer?.customerId ?? '')}
        />
      )}
      options={options}
      renderItem={(item: Site) => <SelectItem site={item} t={t} onLight={onLight} />}
      stringifyItem={(item: Site) =>
        `${item.siteName} ${item.street} ${item.postalCode} ${item.postalCode} ${item.city} ${item.siteNumber}`
      }
      keyExtractor={(item: Site) => item.siteId}
      selectedItem={selectedItem}
      onLight={onLight}
      onSearchTermChange={(term: string) => term && debounceTypeaheadSearchTerm(term)}
      {...restProps}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  sites: get(selectSites(state), 'sites'),
  isFetching: get(selectSites(state), 'isFetching'),
  error: get(selectSites(state), 'error'),
  selectedCustomer: get(selectCustomers(state), 'selectedCustomer'),
  customers: get(selectCustomers(state), 'customers')
})
const mapDispatchToProps = (dispatch) => ({
  fetchSites: (customerId: string) => dispatch(fetchSites(customerId)),
  removeFilter: () => dispatch(removeGlobalSiteFilter())
})
export default connect(mapStateToProps, mapDispatchToProps)(SitesDropdown)
