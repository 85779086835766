import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TestStatus} from './TestStatus.types'
import {SelectDropdown} from '../../Molecules/SelectDropdown/SelectDropdown'

const useStyles = makeStyles(() => ({
  button: {
    width: 222
  }
}))

export interface TestStatusChoice {
  title: string
  value: TestStatus
}

interface SelectItemType {
  testStatus: TestStatusChoice
  onLight?: boolean
}
const SelectItem: React.FC<SelectItemType> = ({testStatus, onLight}) => (
  <Box aria-label="Test status filter">
    <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
      {testStatus.title}
    </Typography>
  </Box>
)

interface Props {
  props?: any
  noSelectionLabel?: any
  onChange: (item: TestStatusChoice) => void
  selectedTestStatus: TestStatus
  onLight?: boolean
}
export const TestStatusDropdown: React.FC<Props> = ({
  onChange,
  selectedTestStatus,
  onLight,
  ...props
}) => {
  const {t} = useTranslation()

  const testStatusChoices: TestStatusChoice[] = [
    {
      title: t('testStatus.showOnlyTested'),
      value: TestStatus.Booked
    },
    {
      title: t('testStatus.showOnlyNotTested'),
      value: TestStatus.None
    }
  ]
  const classes = useStyles()

  const {noSelectionLabel, ...restProps} = props

  return (
    <SelectDropdown
      data-test-id="testStatus-dropdown"
      selectedProps={{
        className: classNames(classes.button)
      }}
      onChange={onChange}
      showSearch={false}
      label={t('testStatus.label')}
      noSelectionLabel={noSelectionLabel || t('testStatus.noSelectionLabel')}
      options={testStatusChoices}
      renderItem={(item: TestStatusChoice) => <SelectItem testStatus={item} onLight={onLight} />}
      stringifyItem={(item: TestStatusChoice) => `${item.title}`}
      keyExtractor={(item: TestStatusChoice) => item.title}
      selectedItem={testStatusChoices.find((testStatus) => testStatus.value === selectedTestStatus)}
      onLight={onLight}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  )
}
